import React from 'react'

import Screen from '../../components/Screen'
import Footer from '../../components/Footer/Footer';
import { withRouter } from "react-router";

class Ending extends Screen {
    constructor(props) {
        super(props)

        this.state = Object.assign(this.state, {
            formParticipation:
                window.sessionStorage.getItem("formParticipation") || false,
        })
    }

  render() {
    const { formParticipation } = this.state;
    return (
      <div className="end-page">
        <div className="content-page">
          <img src={ this.wording.getConfig("mainLogoHomeUrl") } className="logo-main logo-home" alt={this.wording.getConfig("mainLogoHomeAlt")}/>

          <h2 className="title-end" >{this.wording.getLabelNewLine(formParticipation ? "DEFI_TRI_LIVE.ENDING.PAGE_TITLE" : "DEFI_TRI_LIVE.ENDING.PAGE_TITLE_NOT_SUB")}</h2>

          <div className="text-end" >{this.wording.getLabelNewLine(formParticipation ? "DEFI_TRI_LIVE.ENDING.TEXT" : "DEFI_TRI_LIVE.ENDING.TEXT_NOT_SUB")}</div>

          { this.wording.getConfig("endLink1") === true && 
          <div className="btn mustard btn-defi" onTouchStart={()=>{}}>
            <a href={this.wording.getLabel("DEFI_TRI_LIVE.ENDING.BTN_GAME.URL")} target="_blank" rel="noopener noreferrer">
              {this.wording.getLabel("DEFI_TRI_LIVE.ENDING.BTN_GAME.TEXT")}
            </a>
          </div>
          }
          { this.wording.getConfig("endLink2") === true && 
          <div className="btn green btn-guide" onTouchStart={()=>{}}>
            <a href={this.wording.getLabel("DEFI_TRI_LIVE.ENDING.BTN_GUIDE.URL")} target="_blank" rel="noopener noreferrer">
              {this.wording.getLabel("DEFI_TRI_LIVE.ENDING.BTN_GUIDE.TEXT")}
            </a>
          </div>
          }
          { this.wording.getConfig("endLink3") === true && 
          <div className="btn blue btn-partenaire" onTouchStart={()=>{}}>
            <a href={this.wording.getConfig("urlPartner")} target="_blank" rel="noopener noreferrer">
              {this.wording.getConfig("textPartner")}
            </a>
          </div>
          }
          <div className="link-trier">
            <a href={this.wording.getLabel("DEFI_TRI_LIVE.LINK_TRIER.URL")} target="_blank" rel="noopener noreferrer">
              {this.wording.getLabelNewLine("DEFI_TRI_LIVE.LINK_TRIER.TEXT")}
            </a>
          </div>
        </div>

        <div className="block-footer">
          <img src={ this.wording.getConfig("bottomLogoHomeUrl") } className="logo-chrono chrono-home" alt={this.wording.getConfig("bottomLogoHomeAlt")}/>
          <Footer />
        </div>

      </div>
    )
  }

}
export default withRouter(Ending);