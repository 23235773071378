import React from "react";

import { withRouter } from "react-router-dom";
import Header from "../../components/Header";
import Screen from "../../components/Screen";
import QRCode from "../QRCode/QRCode";
import WasteSorting from "../WasteSorting/WasteSorting";
import Footer from "../../components/Footer/Footer";
import Logout from "../../components/Logout/Logout";
import ModalConfirm from "../../components/ModalConfirm/ModalConfirm";

class Playground extends Screen {
  constructor(props) {
    super(props);
    this.conf = {
      scan: {
        class: "scan-page",
        headerName: this.wording.getLabelNewLine(
          "DEFI_TRI_LIVE.QR_CODE.PAGE_NAME"
        ),
      },
      sort: {
        class: "waste-page",
        headerName: this.wording.getLabelNewLine(
          "DEFI_TRI_LIVE.WASTE_SORTING.PAGE_NAME"
        ),
      },
    };
    this.header = React.createRef();

    this.state = Object.assign(this.state, {
      view: "scan",
      conf: this.conf["scan"],
      showModal: false,
    });
  }

  setView(view) {
    this.setState({
      view,
      conf: this.conf[view],
    });
  }
  openModalConfirm() {
    const IfModalIsOpen = this.state.showModal;
    this.setState({ showModal: !IfModalIsOpen });
  }

  preventReload(event) {
    event.returnValue = true;
  }
  componentDidMount() {
    window.addEventListener("beforeunload", this.preventReload);
    if (window.sessionStorage.getItem("gameReady")) {
      window.sessionStorage.removeItem("gameReady");
    } else {
      this.redirect();
    }
  }
  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.preventReload);
  }
  render() {
    return (
      <div>
        <Header
          NamePage={this.state.conf.headerName}
          withChrono="true"
          ref={this.header}
          redirect={() => this.redirect("/game-over")}
        />
        <div className={this.state.conf.class + " page"}>
          {this.state.view === "scan" && (
            <QRCode goToSort={() => this.setView("sort")} />
          )}
          {this.state.view === "sort" && (
            <WasteSorting
              goToScan={() => this.setView("scan")}
              getTime={() =>
                !!this.header.current && !!this.header.current.chrono.current
                  ? this.header.current.chrono.current.state.countdown
                  : 0
              }
            />
          )}
          <div className="bottom-page">
            <Logout OpenModal={() => this.openModalConfirm()} />
            <Footer />
          </div>
          <ModalConfirm
            ModalIsOpen={this.state.showModal}
            confirmAction={() => {
              if (this.wording.getConfig("hasChrono"))
                this.header.current.chrono.current.stop();
              else this.redirect("/score");
            }}
            CloseModal={() => this.openModalConfirm()}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(Playground);
