import React from "react";

import { withRouter } from "react-router-dom";
import Screen from "../../components/Screen";

import { Game } from "../../logic";

import ValidationWaste from "../../components/ValidationWaste/ValidationWaste";

class WasteSorting extends Screen {
  constructor(props) {
    super(props);

    this.game = Game;
    this.header = React.createRef();

    this.state = Object.assign(this.state, {
      waste: null,
      showValidation: false,

      formParticipation:
        JSON.parse(window.sessionStorage.getItem("formParticipation")) || false,
      participationId:
        window.sessionStorage.getItem("formParticipationId") || false,
      operationId: this.wording.getConfig("id"),
    });
  }

  componentDidMount() {
    this.setState({ waste: this.game.getCurrentWaste() });
  }

  wastebinClicked(index) {
    const IfValidationIsOpen = this.state.showValidation;
    this.setState({ showValidation: !IfValidationIsOpen });

    const wastebin = this.game.getWastebinByIndex(index);
    this.game.answer(wastebin);

    const { operationId, formParticipation, participationId } = this.state;
    const answers = this.game.getAnswers();
    const score = this.game.getScore();
    const time = this.props.getTime();
    const answerCount = answers.length;
    this.api.updateScore({
      operationId,
      time,
      participationId: participationId,
      answerCount,
      score,
      email: formParticipation.email || false,
    });
    setTimeout(() => {
      if (this.game.hasNext()) {
        // More Product to sort, redirect to scan
        // console.log('More Product to sort, redirect to scan');
        this.props.goToScan();
      } else {
        // No More Product to sort, redirect to end of Game
        // console.log('No More Product to sort, redirect to end of Game', this.wording.getConfig("hasChrono"));
        if (this.wording.getConfig("hasChrono") === true) {
          this.redirect("/game-over");
        } else {
          this.redirect("/score");
        }
      }
    }, 1000);
  }

  render() {
    const wastebins = this.game.getWastebins();
    return (
      <React.Fragment>
        <div className="content-waste-page">
          <div className="block-product">
            {!!this.state.waste && (
              <div className="content-product">
                <img
                  src={this.game.getImage(this.state.waste.imageUrl)}
                  className="product-picture"
                  alt={this.state.waste.label}
                />
                <div className="name-product">{this.state.waste.label}</div>
              </div>
            )}
          </div>
          <div className="waste-sorting-block">
            {Object.keys(wastebins).map((value, index) => {
              return (
                <div
                  className="waste-block"
                  onClick={this.wastebinClicked.bind(this, value)}
                  key={index}
                >
                  <div
                    className="waste btn"
                    style={{ backgroundColor: wastebins[value].color }}
                    onTouchStart={() => {}}
                  >
                    <img
                      src={this.game.getImage(wastebins[value].imageUrl)}
                      className="waste-picture"
                      alt={wastebins[value].label}
                    />
                  </div>
                  <div className="name-waste">
                    <span>{wastebins[value].label}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <ValidationWaste ValidationIsOpen={this.state.showValidation} />
      </React.Fragment>
    );
  }
}

export default withRouter(WasteSorting);
