import React from 'react'

import { withRouter } from 'react-router-dom'
import Screen from '../../components/Screen'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { Game } from '../../logic'


import Chrono from '../../assets/img/chrono-red.png';

class GameOver extends Screen {
    game = Game
    constructor(props) {
      super(props)
  
      this.state = Object.assign(this.state, {
        finalTime: 0,
      })
    }

    componentDidMount() {
      const finalTime = this.game.getFinalTime();
  
      this.setState({ finalTime })
    }
    render() {
        return (
            <div className="gameover-page page">
                <Header NamePage={this.wording.getLabelNewLine("DEFI_TRI_LIVE.GAMEOVER.PAGE_NAME")}/>
                <div className="content-page">
                    <div className="title-page">{this.wording.getLabelNewLine("DEFI_TRI_LIVE.GAMEOVER.PAGE_TITLE")}</div>
                    <div className="text-page">
                        <div className="intro">{this.wording.getLabelNewLine("DEFI_TRI_LIVE.GAMEOVER.TEXT")}</div>
                        <div id="chrono">
                            <img src={Chrono} alt={this.wording.getLabel("DEFI_TRI_LIVE.GAMEOVER.CHRONO_ALT")} />
                            <div className="chrono-text">
                                <div className="time">{this.state.finalTime}</div>
                                <div className="unit">{this.wording.getLabelNewLine("DEFI_TRI_LIVE.GAMEOVER.CHRONO_UNIT")}</div>
                            </div>
                        </div>
                    </div>
                    <button className="btn default btn-score" type="submit" onClick={()=>{
                        this.redirect('/score')
                    }}>
                        <span>{this.wording.getLabel("DEFI_TRI_LIVE.GAMEOVER.BTN")}</span>
                    </button>
                </div>
                <div className="bottom-page">
                    <Footer />
                </div>
            </div>
        )
    }

}

export default withRouter(GameOver)
