import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Wording } from '../../library';

class ModalConfirm extends Component {

    wording = Wording;

    render() {
        const ClassOpenModal = this.props.ModalIsOpen ? 'show' : '';
        return (
        <div className={"modal-confirm " + ClassOpenModal}>
            <div className="content-modal">
                <div className="title">{this.wording.getLabelNewLine("DEFI_TRI_LIVE.MODALS.TEXT_CONFIRM")}</div>
                <div className="btn default btn-yes" onTouchStart={()=>{}} onClick={this.props.confirmAction}>
                    <span>{this.wording.getLabel("DEFI_TRI_LIVE.MODALS.BTN_CONFIRM_YES")}</span>
                </div>
                <div className="btn grey btn-no" onTouchStart={()=>{}} onClick={this.props.CloseModal}>
                    <span>{this.wording.getLabel("DEFI_TRI_LIVE.MODALS.BTN_CONFIRM_NO")}</span>
                </div>
            </div>
        </div>
        )
    }
}

export default withRouter(ModalConfirm)