import React, { Component } from "react";
import { Wording } from "../../library";
import Chrono from "../Chrono/Chrono";

export default class Footer extends Component {
  wording = Wording;
  constructor(props) {
    super(props);
    this.chrono = React.createRef();
  }

  render() {
    return (
      <div className="header-block">
        {this.props.withChrono &&
        this.wording.getConfig("hasChrono") === true ? (
          <Chrono ref={this.chrono} redirect={() => this.props.redirect()} />
        ) : (
          <img
            src={this.wording.getConfig("headerLogoUrl")}
            className="logo-chrono"
            alt={this.wording.getConfig("headerLogoAlt")}
          />
        )}
        <div className="name-page-block">
          <div className="name-page">{this.props.NamePage}</div>
          <div className="line-pict"></div>
        </div>
      </div>
    );
  }
}
