import React, { Component } from "react";
import { Wording } from "../../library";
import { Game } from "../../logic";

export default class Chrono extends Component {
  wording = Wording;
  game = Game;
  duration = this.wording.getConfig("chronoDuration");
  animationFrame = null;
  isRunning = true;

  constructor(props) {
    super(props);
    this.state = {
      timerClass: "pause",
      countdown: 0,
    };
  }
  start() {
    let countdown = this.duration;

    const tick = () => {
      if (this.isRunning) {
        if (countdown > 0) {
          this.setState({ countdown });
          countdown--;

          this.setState({ timerClass: "" });
          if (countdown <= this.duration / 2) {
            this.setState({ timerClass: "half" });
          }
          if (countdown <= this.duration / 4) {
            this.setState({ timerClass: "quart" });
          }
          if (countdown <= this.duration / 4 / 2) {
            this.setState({ timerClass: "quart warn" });
          }
        } else {
          this.setState({ countdown: 0 });
          this.stop();
        }
      }
    };
    tick();
    this.animationFrame = setInterval(tick, 1000);
  }

  stop() {
    this.setState({ timerClass: "stop" });
    this.isRunning = false;
    this.game.setFinalTime(this.duration - this.state.countdown);

    setTimeout(() => {
      this.props.redirect();
    }, 1000);
  }

  componentDidMount() {
    // setTimeout(() => {
    this.start();
    // }, 3000)
  }

  componentWillUnmount() {
    this.stop();
    clearTimeout(this.animationFrame);
    this.game.setFinalTime(this.duration - this.state.countdown);
  }

  render() {
    return (
      <div className={"timer-group " + this.state.timerClass}>
        <div className="timer">
          <div className="hand">
            <span></span>
          </div>
          <div className="hand">
            <span></span>
          </div>
        </div>
        <div className="face">
          <div className="time">{this.state.countdown}</div>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html: `
            .timer-group .timer .hand span{
              animation-duration: ${this.duration}s;
              border-bottom-color: ${this.wording.getConfig(
                "chronoColorPhase1"
              )};
              border-left-color: ${this.wording.getConfig("chronoColorPhase1")};
            }
            .timer-group.half .hand span {
                border-bottom-color: ${this.wording.getConfig(
                  "chronoColorPhase2"
                )};
                border-left-color: ${this.wording.getConfig(
                  "chronoColorPhase2"
                )};
            }
            .timer-group.quart .hand span {
                border-bottom-color: ${this.wording.getConfig(
                  "chronoColorPhase3"
                )};
                border-left-color: ${this.wording.getConfig(
                  "chronoColorPhase3"
                )};
            }
            .timer-group.warn {
                color: ${this.wording.getConfig("chronoColorPhase3")};
            }
        `,
          }}
        />
      </div>
    );
  }
}
