import React from 'react'

import { withRouter, Link } from "react-router-dom"

import Screen from '../../components/Screen'
import Footer from '../../components/Footer/Footer'
import ModaliOS from '../../components/ModaliOS/ModaliOS'
import ModalAndroid from '../../components/ModalAndroid/ModalAndroid'

class Home extends Screen {

  constructor(props) {
    super(props)

    const userAgent = window.navigator.userAgent

    const isIOS = !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i)

    const isAndroid = !!userAgent.match(/Android/i)

    const is_chrome_android = !!userAgent.match(/Chrome\//i)

    const containsVersion = !!userAgent.match(/Version\//i) // used by safari ios

    const displayIosUseSafari = isIOS && !containsVersion

    const displayAndroidUseChrome = isAndroid && !is_chrome_android

    this.state = Object.assign(this.state, {
      displayIosUseSafari,
      displayAndroidUseChrome
    })
  }

  render() {

    // console.log("User agent", window.navigator.userAgent)

    return (
      <div className="home-page page">
        <div className="content-page">
          <img src={ this.wording.getConfig("mainLogoHomeUrl") } className="logo-main logo-home" alt={this.wording.getConfig("mainLogoHomeAlt")}/>
          <div className="text-home">{this.wording.getConfigNewLine("titleOperation")}</div>
          <div className="btn default btn-home" onTouchStart={()=>{}}>
            <Link to={'/'+this.operation+'/newsletter'}>{this.wording.getLabel("DEFI_TRI_LIVE.HOME.BTN")}</Link>
          </div>
        </div>
        <div className="block-footer">
          <img src={ this.wording.getConfig("bottomLogoHomeUrl") } className="logo-chrono chrono-home" alt={this.wording.getConfig("bottomLogoHomeAlt")}/>
          <Footer />
        </div>
        <ModaliOS ModalIsOpen={this.state.displayIosUseSafari}/>
        <ModalAndroid ModalIsOpen={this.state.displayAndroidUseChrome}/>

      </div>
    )
  }

}

export default withRouter(Home);