import React from 'react'

import { withRouter } from "react-router-dom"

import Screen from '../../components/Screen'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

class Error extends Screen {

    constructor(props) {
        super(props);

        this.state = Object.assign(this.state, {
            code: this.props.code || 500,
        })
    }

  render() {

    // console.log("User agent", window.navigator.userAgent)

    return (
        <div className="error-page page">
            <Header NamePage={this.wording.getLabelNewLine("DEFI_TRI_LIVE.VALIDATE.PAGE_NAME")}/>
            <div className="content-page">
                <div className="title-page">{this.wording.getLabelNewLine("ERROR."+this.state.code+".TITLE")}</div>
                <div className="text-page">
                    {this.wording.getLabelNewLine("ERROR."+this.state.code+".DESC")}
                </div>
            </div>
            <div className="bottom-page">
                <Footer />
            </div>
        </div>
    )
  }

}

export default withRouter(Error);