import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Wording } from '../../library';

class ModaliOS extends Component {

  wording = Wording;

    render() {
        const ClassOpenModal = this.props.ModalIsOpen ? 'show' : '';
        return (
        <div className={"modal-confirm modal-ios " + ClassOpenModal}>
            <div className="content-modal">
                <div className="title">{this.wording.getLabelNewLine("DEFI_TRI_LIVE.MODALS.TEXT_IOS")}</div>
            </div>
        </div>
        )
    }
}

export default withRouter(ModaliOS)