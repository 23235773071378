import { Component } from 'react'
import { Wording, Api } from '../../library';
import { Game } from '../../logic';

class Screen extends Component {
  api = null
  operation = null

  constructor(props) {
    super(props)
    
    this.operation = this.props.match.params.operation;
    this.api = new Api(this.operation)
    this.wording = Wording;

    // On charge les informations de l'opération si on trouve un path dans l'url et si les info n'ont pas été déjà chargé (ou que le nom de l'opération à changé)
    if (this.operation) {
      // console.log("Fetch Operation info", this.state, this.operation)
      Wording.init(this.operation).then(newWording => {
        if(newWording !== false && newWording.getLabel("CONFIG.slug") === this.operation) {
          this.setState({ wording: newWording })
          Game.updateWasteDetail(newWording.getLabel("CONFIG"), this.operation)
          if(Game.wastes !== false) this.setState({ wastes: Game.wastes })
          if(Game.wastebins !== false) this.setState({ wastes: Game.wastebins })
          this.setState({'loaded': true})
        }
        else {
          console.log("Operation couldn't load");
          this.props.history.push('/404') // page d'erreur
        }
      }).catch(e => {
        console.log("Operation doesn't exist", e);
        this.props.history.push('/404') // page d'erreur
      })
    }
    
    this.state = {
      api: this.api,
      wording: Wording,
      wastes: Game,
      loaded: false
    }
  }

  redirect (url) {
    url = url || ''
    this.props.history.push('/' + this.operation + url)
  }

}
export default Screen