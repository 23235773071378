import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import { Home, Newsletter, Playground, Score, Validate, GameOver, Ending, Error } from './screens';

class App extends Component {

  render() {
      return (
          <Router basename={process.env.PUBLIC_URL}>
              <Switch>
                  <Route path="/404">
                      <Error code="404"/>
                  </Route>
                  <Route path="/:operation/score">
                      <Score />
                  </Route>
                  <Route path="/:operation/scan">
                      <Playground />
                  </Route>
                  <Route path="/:operation/newsletter">
                      <Newsletter />
                  </Route>
                  <Route path="/:operation/validate">
                      <Validate />
                  </Route>
                  <Route path="/:operation/game-over">
                    <GameOver />
                  </Route>
                  <Route path="/:operation/ending">
                    <Ending />
                  </Route>
                  <Route path="/:operation">
                      <Home />
                  </Route>
                  <Route path="/">
                      <Redirect to="/citeo" />
                  </Route>
              </Switch>
          </Router>
      );
  }
}

export default App;
